<template>
  <nav-bar />
  <div class="container-fluid h-100">
    <div class="row hidden-toolbar"></div>
    <div class="row main-content">
      <nav class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse position-fixed h-100">
        <side-bar />
      </nav>
      <div class="col-md-9 ms-sm-auto col-lg-10 p-4">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./NavBar.vue";
import SideBar from "./SideBar.vue";

export default {
  components: {
    NavBar,
    SideBar,
  },
};
</script>

<style scoped>
.main-content {
  height: calc(100% - 70px);
}
.hidden-toolbar {
  height: 70px;
}
</style>