<template>
  <nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0">
    <span class="w-100"></span>
    <ul class="navbar-nav px-3">
      <!--
      <li class="nav-item text-nowrap">
        <router-link to="/home" class="nav-link" >Home</router-link>
      </li>
      -->
      <li class="nav-item text-nowrap">
        <router-link to="/home" class="nav-link" @click="logout()" >Log out</router-link>
      </li>
    </ul>
  </nav>
</template>

<style scoped>
.navbar {
  height: 70px;
}
.navbar-nav{
  display: flex;
  flex-direction: row;
}
.nav-item{
  margin-left: 20px;
}
</style>

<script>
export default{
  setup() {
    const logout = async () =>{
      localStorage.setItem('token','');
      localStorage.setItem('role','');
    }
    return{
      logout,
    }
  },
}
</script>
