<template>
  <div class="lemodal-window">
    <div>
      <div class="lemodal-content">
      <strong>
            Qualitäts-/ Hygienische-<br>
            und Sicherheitsvorschriften<br>
            für Besucher und Dienstleister
        </strong>
        <p>Sehr geehrte Besucher, sehr geehrte Dienstleister,</p>
        <p><strong>wir heißen Sie herzlich Willkommen in unserem Haus. Damit alle Hygiene- und Sicherheitsvorschriften eingehalten werden, bitten wir Sie folgendes zu beachten:</strong></p>
        <p>
            <u><strong>Präambel:</strong></u><br>
            Wir sind ein Lebensmittelbetrieb, so dass uns die Sorgfaltspflicht im Umgang mit unseren eingelagerten Artikeln obliegt.
        </p>
        <p>
            <u>Dienstleister/ Handwerker</u>
            <ul>
                <li>Sie sollten deshalb bei Ihren Reparaturen darauf achten, dass keine Fremdkörper auf die Produkte gelangen.</li>
                <li>Wenn mobile Gerätschaften repariert werden, können Sie diese Arbeiten im Leergutlager verrichten.</li>
                <li>Planen zum Abdecken der Ware sind mitzubringen. Notfalls können Sie von uns Planen ausleihen.</li>
                <li>Eventuelle Verletzungen müssen mit einem Pflaster verbunden werden, welches Sie von Ihrem Begleiter erhalten.</li>
            </ul>
        </p>
        <p>
            <u>Besucher/ Betriebsfremde:</u>
            <ul>
                <li>Das Tragen von sichtbaren, freitragenden Schmuckgegenständen (Ketten, Ohrringen, Ringen usw.) ist verboten. Eine Ausnahme gilt für die Uhr und den Ehering.</li>
                <li>Bevor Sie den Lagerbereich betreten, waschen und desinfizieren Sie gegebenenfalls bitte Ihre Hände.</li>
                <li>Es ist nicht erlaubt unsere Produkte zu berühren.</li>
                <li>Vermeiden Sie in der Nähe unserer Produkte zu husten oder zu niesen.</li>
                <li>Im Lagerbereich darf nicht geraucht und gegessen werden.</li>
                <li>Es gilt ein Rauchverbot im gesamten Haus. Das Rauchen ist nur draußen unter dem dafür vorgesehenen Vordach gestattet.</li>
                <li>Das Benutzen von Foto- und Filmapparaten ist nicht erlaubt.</li>
            </ul>
        </p>
        <p>Alle Informationen, die Ihnen von der Firma Ruwisch & Zuck - Die Käsespezialisten GmbH & Co. KG mitgeteilt werden, sind streng vertraulich. Wir möchten Sie ausdrücklich darauf hinweisen, dass Sie durch das Betreten unseres Betriebes zur Geheimhaltung verpflichtet sind.</p>
        <p>In Notfällen (Brand, Unfall) folgen Sie bitte den Anweisungen der Begleitperson und der Mitarbeiter im Lager.</p>
        <p>Nähere Informationen zu Ihren Daten und Rechten hinsichtlich Speicherung, Auskunft, Berichtigung, Löschung, Sperrung und Widerruf nach DS-GVO, finden Sie auf unserer Website – <u>www.ruwischzuck.de</u></p>
        <p>Wir bedanken uns schon einmal vorab für Ihr Verständnis.</p>
        <p>Ihr QM-Team der Firma Ruwisch & Zuck –Die Käsespezialisten GmbH & Co. KG</p>
      </div>
      <div class="lemodal-footer">
        <span class="lemodal-close" @click="$emit('close')">X</span>
        <!--<span class="lemodal-close-accept">Ich akzeptiere die Bedingungen</span>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
