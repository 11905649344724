<template>
  <div class="w-75 p-4 bg-white">
    <div class="mb-4">
      <div>
        <div class="fs-5 fw-bold text-dark lh-1 title-line">Department Edit</div>
      </div>
    </div>
    <div class="visitor-section">
        <div class="table-responsive">
            <form action="" @submit.prevent="updateData" class="p-3">
              <div class="col-8 col-sm-10 mb-2">
                <div class="form-floating">
                  <input type="text" class="form-control" v-model="department.name" />
                  <label>Name</label>
                </div>
              </div>
              <div class="col-8 col-sm-10 mb-2">
                <div class="form-floating">
                  <select class="form-select" v-model="department.default_user">
                      <option selected></option>
                      <option
                        v-for="item in comboUser.data"
                        :key="item.id"
                        v-bind:value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  <label>Department Manager</label>
                </div>
              </div>
              <div class="col-8 col-sm-10 mb-2">
                  <router-link to="/admin/department" exact >
                      <button type="button" class="btn btn-danger mx-lg-3 float-end">Cancel</button>
                  </router-link>
                  <button type="submit" class="btn btn-primary mx-lg-3 float-end">Save</button>
              </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import departmentServices from "../../../services/DepartmentServices";
import userServices from "../../../services/UserServices";

export default {
  setup() {
    const { comboUser, getComboUser } = userServices();
    const { department, getAnDepartment, updateDepartment } = departmentServices();
    const getData = async (id) => {
      await getAnDepartment(id);
      await getComboUser();
    };
    let flag = true;
    const updateData = async () => {
      if(flag){
        flag = false;
        await updateDepartment({department});
      }
    };
    onMounted(getData());
    return {
      department,
      updateData,
      comboUser,
    };
  },
};
</script>
