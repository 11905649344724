import { ref } from 'vue'
import axios from '../util/api';
import { useRouter } from 'vue-router';

export default function visitorServices() {
    const router = useRouter()
    const visitors = ref([])
    const visitor = ref('');
    const errors = ref('')

    const getVisitors = async () => {
        const response = await axios.get('/visitors');
        visitors.value = response.data.data;
    }

    const getVisitor = async () => {
        const response = await axios.get('/visitors/getBySignoutKey/' + router.currentRoute._value.params.signoutKey);
        visitor.value = response.data.data;
    }

    const saveSignoutData = async (data) => {
        errors.value = ''
        try {
            await axios.post('/visitors/saveBySignoutKey/' + router.currentRoute._value.params.signoutKey, data)
            await router.push('/thanks');
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
        }
    }

    const storeData = async (data) => {
        errors.value = ''
        try {
            await axios.post('/visitors', data)
            await router.push('/thanks');
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
        }
    }

    const destroyVisitor = async (id) => {
        await axios.put('/visitors/' + id);
        await router.push('/thanks');
    }

    return {
        visitors,
        visitor,
        errors,
        storeData,
        saveSignoutData,
        getVisitors,
        getVisitor,
        destroyVisitor
    }
}
