<template>
    <div class="mb-4">
        <div class="d-flex align-items-center">
            <div class="fs-5 fw-bold text-dark lh-1 title-line w-100">
                Auschecken
            </div>
        </div>
    </div>
    <form action="" @submit.prevent="saveSignout">
	<div>
		<div class="bg-white rounded-3">
			<div class="border-bottom p-4 fw-bold">{{visitor.firstname}} {{visitor.lastname}}</div>
			<div class="d-flex align-items-center border-bottom p-4">
				<div class="d-flex align-items-center me-2">
					<i class="fas fa-arrow-right fs-7 text-yellow me-1"></i>
					<i class="fas fa-door-open fs-5 text-yellow"></i>
				</div>
				<div>
					<div>{{visitor.check_in_at}}</div>
				</div>
			</div>
			<div class="d-flex align-items-center border-bottom p-4">
				<div class="d-flex align-items-center me-2">
					<i class="fas fa-door-open fs-5 text-yellow"></i>
					<i class="fas fa-arrow-right fs-7 text-yellow me-1"></i>
				</div>
				<div>
					<Datepicker v-model="date" name="checkOutDateTime" required format="dd.MM.yyyy HH:mm" :minDate="minDate" :maxDate="maxDate" />
				</div>
			</div>
			<div class="d-flex align-items-center p-4">
				<button type="submit" class="btn btn-yellow text-uppercase fw-bold">
					Bestätigen
				</button>
			</div>
		</div>
	</div>
	</form>
</template>

<script>
import { reactive, onMounted } from "vue";
import visitorServices from '../../services/VisitorServices';

import { ref } from 'vue';
import { getMonth, getYear, subMonths } from 'date-fns';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


export default {
	components: { Datepicker },
	setup() {
		const date = ref();
		const minDate = subMonths(new Date(getYear(new Date()), getMonth(new Date())), 1);
		const maxDate = new Date();

		const form = reactive({
			date: date,
		});

		const { visitor, getVisitor, errors, saveSignoutData } = visitorServices();

		const getData = async (signoutKey) => {
			await getVisitor(signoutKey);
		};

		onMounted(getData());

		const saveSignout = async () => {
			console.log(form);
			await saveSignoutData({ ...form });
		};

		return {
			errors,
			visitor,
			saveSignout,
			date,
			minDate,
			maxDate,
		}
    },
    methods: {
    },
}
</script>
