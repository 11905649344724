<template>
  <!-- <div v-if="errors">
		<div v-for="(v, k) in errors" :key="k" class="bg-red-500 text-white rounded font-bold mb-4 shadow-lg py-2 px-4 pr-0">
			<p v-for="error in v" :key="error" class="text-sm">
			{{ error }}
			</p>
		</div>
	</div> -->

    <div class="mb-4">
        <div class="d-flex align-items-center">
            <div
                class="d-flex align-items-center justify-content-center btn bg-white p-2 p-sm-3 rounded-circle border me-3"
                @click="goBack"
            >
                <img
                    src="../../assets/icons/arrow-left.svg"
                    width="30"
                    height="30"
                    alt="Back"
                />
            </div>
            <div class="fs-5 fw-bold text-dark lh-1 title-line w-100">
                Besuch anmelden
            </div>
        </div>
    </div>
  <div>
    <form action="" @submit.prevent="saveCompany">
      <div class="row">
        <div class="col-12 mb-2">
          <div class="form-floating">
            <select class="form-select" v-model="form.department">
              <option selected></option>
              <option
                v-for="item in comboDepartment.data"
                :key="item.id"
                v-bind:value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <label>Abteilung</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-2">
          <div class="form-floating">
            <select class="form-select" v-model="form.contact_person">
              <option selected></option>
               <template v-for="item in comboUser.data">
                  <option v-if="form.department == item.department.id" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </option>
                </template>
            </select>
            <label>Ansprechpartner</label>
          </div>
        </div>
      </div>
      <div class="row gx-2">
        <div class="col-4 col-sm-3 mb-2">
          <div class="form-floating">
            <select class="form-select" v-model="form.gender">
              <option selected></option>
              <option value="male">Herr</option>
              <option value="female">Frau</option>
            </select>
            <label>Herr</label>
          </div>
        </div>
        <div class="col-8 col-sm-4 mb-2">
          <div class="form-floating">
            <input type="text" class="form-control" v-model="form.firstname" />
            <label>Vorname</label>
          </div>
        </div>
        <div class="col-12 col-sm-5 mb-2">
          <div class="form-floating">
            <input type="text" class="form-control" v-model="form.lastname" />
            <label>Nachname</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-2">
          <div class="form-floating">
            <input type="text" class="form-control" v-model="form.company" />
            <label>Firma</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-2">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              v-model="form.license_plate"
            />
            <label>KFZ-Kennzeichen</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-2">
          <div class="form-check d-flex align-items-center">
            <input
              class="form-check-input me-3"
              type="checkbox"
              v-model="form.checked"
            />
            <label class="form-check-label">
              Ich habe die Besucherregeln zur
              <span class="d-block"
                ><a
                  href="#"
                  id="show-modal"
                  @click="showModal = true"
                  accept-text="Ich akzeptiere die Bedingungen"
                  class="text-decoration-underline"
                  >Kenntnis genommen</a
                >
                <span class="text-danger">*</span></span
              >
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-4">
          <button
            type="submit"
            class="btn btn-yellow w-100 text-uppercase fw-bold"
          >
            Bestätigen
          </button>
        </div>
      </div>
    </form>
    <le-modal v-if="showModal" @close="showModal = false"></le-modal>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import visitorServices from "../../services/VisitorServices";
import departmentServices from "../../services/DepartmentServices";
import userServices from "../../services/UserServices";
import leModal from "../../components/LeModal";

export default {
  data() {
    return { showModal: false };
  },
  setup() {
    const form = reactive({
      department: "",
      contact_person: "",
      gender: "",
      firstname: "",
      lastname: "",
      company: "",
      license_plate: "",
      checked: false,
    });

    const { errors, storeData } = visitorServices();
    const { comboDepartment, getPublicComboDepartment } = departmentServices();
    const { comboUser, getPublicComboUser } = userServices();

    const getData = async () => {
      await getPublicComboDepartment();
      await getPublicComboUser();
    };

    onMounted(getData);

    const saveCompany = async () => {
      if (form.checked) await storeData({ ...form });
    };

    return {
      errors,
      form,
      comboDepartment,
      comboUser,
      saveCompany,
    };
  },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
    components: {
      leModal
    }
};
</script>
