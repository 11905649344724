<template>
  <div class="w-75 p-4 bg-white">
    <div class="mb-4">
      <div>
        <div class="fs-5 fw-bold text-dark lh-1 title-line">Employee Create</div>
      </div>
    </div>
    <div class="visitor-section">
        <div class="table-responsive">
            <form action="" @submit.prevent="saveData" class="p-3">
              <input type="password" style="display: none;">
              <div class="col-8 col-sm-10 mb-2">
                <div class="form-floating">
                  <input type="text" class="form-control" v-model="form.name" />
                  <label>Name</label>
                </div>
              </div>
              <div class="col-8 col-sm-10 mb-2">
                <div class="form-floating">
                  <input type="email" class="form-control" v-model="form.email" />
                  <label>E-mail</label>
                </div>
              </div>
              <div class="col-8 col-sm-10 mb-2">
                <div class="form-floating">
                  <input type="password" class="form-control" v-model="form.password" />
                  <label>Password</label>
                </div>
              </div>
              <div class="col-8 col-sm-10 mb-2">
                <div class="form-floating">
                  <input type="password" class="form-control" v-model="form.passwordRepeat" />
                  <label>Repeat password</label>
                </div>
              </div>
              <div class="col-8 col-sm-10 mb-2">
                <div class="form-floating">
                  <select class="form-select" v-model="form.department">
                      <option selected></option>
                      <option
                        v-for="item in comboDepartment.data"
                        :key="item.id"
                        v-bind:value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  <label>Department Name</label>
                </div>
              </div>
              <div class="col-8 col-sm-10 mb-2">
                <router-link to="/admin/user" exact >
                  <button type="button" class="btn btn-danger mx-lg-3 float-end">Cancel</button>
                </router-link>
                <button type="submit" class="btn btn-primary mx-lg-3 float-end">Save</button>
              </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import departmentServices from "../../../services/DepartmentServices";
import userServices from "../../../services/UserServices";
export default {
  setup() {
    const form = reactive({
      name: "",
      email: "",
      password: "",
      passwordRepeat: "",
      department: "",
    });
    const { comboDepartment, getComboDepartment } = departmentServices();
    const { storeUser } = userServices();
    let flag = true;
    const saveData = async () => {
      if(flag){
        flag = false;
        await storeUser({ ...form });
      }
    };

    const getData = async () => {
      await getComboDepartment();
    };

    onMounted(getData);

    return {
      form,
      saveData,
      comboDepartment,
    };
  },
};
</script>
