<template>
    <div class="w-100 p-4 bg-white">
        <div class="mb-1">
            <div>
                <div class="fs-5 fw-bold text-dark lh-1 title-line">Employee</div>
            </div>
        </div>
        <div class="visitor-section">
            <div class="table-responsive">
                <div class="float-end my-sm-4 mx-sm-4">
                    <router-link to="/admin/user/create" exact >
                        <button type="button" class="btn btn-primary">Add</button>
                    </router-link>
                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <!--<th scope="col">#</th>-->
                            <th scope="col">Name</th>
                            <th scope="col">Department</th>
                            <th scope="col" class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in users.data" :key="item.id"  >
                            <!--<th class="align-middle" scope="row">{{(index + 1) + (pageNum - 1) * 50 }}</th>-->
                            <td class="align-middle">{{item.name}}</td>
                            <td class="align-middle" v-if="item.department">{{item.department.name}}</td>
                            <td class="align-middle" v-else></td>
                            <td>
                                <div class="p-1 text-center">
                                    <router-link :to="'user/edit/' + item.id" exact >
                                        <button type="button" class="btn_tp text-yellow" data-bs-toggle="tooltip" title="Edit">
                                            <i class="fas fa-pen fs-6 me-1"></i>
                                        </button>
                                    </router-link>
                                    <button type="button" class="btn_tp" data-bs-toggle="tooltip" title="Delete" v-on:click="deleteData(item.id)">
                                        <i class="fas fa-trash-alt fs-6 me-1 del_icon"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <page-nation :count="Math.ceil(users.count/50)" v-on:set-page="getData" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import userServices from "../../../services/UserServices";
import PageNation from "../../../layout/PageNation.vue";

export default {
    components: {
        PageNation,
    },
  setup() {
    const pageNum = ref({});
    const { users, getUsers, deleteUser } = userServices();
    const getData = async (page) => {
      await getUsers(page);
      pageNum.value = page;
    };

    const deleteData = async(id) => {
        await deleteUser(id);
    };

    onMounted(getData(1));
    return {
      users,
      deleteData,
      getData,
      pageNum,
    };
  },
};
</script>