import { ref } from 'vue'
import axios from '../util/api';
import { useRouter } from 'vue-router';

export default function loginServices() {
    const router = useRouter()
    const errors = ref('')

    const emailAuthentication = async (formdata) => {
        try{
            const response = await axios.post('/login',formdata);

            axios.defaults.headers.common.Authorization =
                        "Bearer " + response.data.access_token;
                    localStorage.setItem("token", response.data.access_token);
                    localStorage.setItem("role", response.data.user.role);
            await router.push('/admin/visitors');
        }catch(e){
            if (e.response.status === 401) {
                alert(e.response.data.error);
                errors.value = e.response.data.error
            }else if(e.response.status === 422){
                alert(e.response.data.error);
                errors.value = e.response.data.error
            }
        }
    }

    const redirect = async() =>{
        let token = localStorage.getItem('token');
        if(token != '' && token != 'undefined') {
            await router.push('/admin/visitors');
        }
    }

    return {
        redirect,
        emailAuthentication,
    }
}