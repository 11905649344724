<template>
  <!--<div class="container-lg h-100 p-4 d-flex flex-column scroll-content">-->
  <div class="h-100 p-4 d-flex flex-column scroll-content">
    <router-view />
  </div>
  <background-animation />
</template>

<script>
import BackgroundAnimation from "../components/BackgroundAnimation.vue";
export default {
  components: {
    BackgroundAnimation,
  },
};
</script>