import { ref } from 'vue'
import axios from '../util/api';

export default function AdminServices() {
    const visitors = ref([])

    const getVisitors = async () => {
        const response = await axios.get('/admin/visitors/log', { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } });
        visitors.value = response.data.data;
    }

    const destroyVisitor = async (id) => {
        await axios.put('/admin/visitors/' + id, {}, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } });
        getVisitors();
    }

    return {
        visitors,
        getVisitors,
        destroyVisitor,
    }
}
