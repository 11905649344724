<template>
    <div class="mb-4">
        <div class="d-flex align-items-center">
            <div
                class="d-flex align-items-center justify-content-center btn bg-white p-2 p-sm-3 rounded-circle border me-3"
                @click="goBack"
            >
                <img
                    src="../../assets/icons/arrow-left.svg"
                    width="30"
                    height="30"
                    alt="Back"
                />
            </div>
            <div class="fs-5 fw-bold text-dark lh-1 title-line w-100">
                Auschecken
            </div>
        </div>
    </div>
	<div>
		<template v-for="item in visitors" :key="item.id">
			<div class="navigation-option d-flex align-items-center bg-white px-4 py-4 rounded-3 mb-2 justify-content-between" @click="deleteVisitor(item.id)">
				<div class="d-flex align-items-center me-3">
					<div class="d-flex align-items-center me-2">
						<i class="fas fa-arrow-right fs-7 text-yellow me-1"></i>
						<i class="fas fa-door-open fs-5 text-yellow"></i>
					</div>
					<div>
						<div>{{item.check_in_at}}</div>
						<div>{{item.user.name}}</div>
					</div>
				</div>
				<div>{{item.codeName}}</div>
			</div>
		</template>
	</div>
</template>

<script>
import { onMounted } from "vue";
import visitorServices from '../../services/VisitorServices';

export default {
    setup() {
		const { visitors, getVisitors, destroyVisitor } = visitorServices();

		onMounted(getVisitors);

		const deleteVisitor = async (id) => {
			await destroyVisitor(id);
		};

        return {
			visitors,
			deleteVisitor
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
}
</script>
