import { ref } from 'vue'
import axios from '../util/api';
import { useRouter } from 'vue-router';

export default function departmentServices() {
    const router = useRouter()
    const departments = ref({});
    const comboDepartment = ref({});
    const department = ref('');
    const errors = ref('')

    const getDepartments = async (page) => {
        const response = await axios.get('/admin/departments/page/' + page, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        departments.value = response.data;
    }

    const getComboDepartment = async () => {
        const response = await axios.get('/admin/departments', { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        comboDepartment.value = response.data;
    }

    const getPublicComboDepartment = async () => {
        const response = await axios.get('/departments')
        comboDepartment.value = response.data;
    }

    const getAnDepartment = async () => {
        const response = await axios.get('/admin/departments/' + router.currentRoute._value.params.id, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } });
        department.value = response.data.data;
    }

    const storeDepartment = async (data) => {
        errors.value = ''
        try {
            await axios.post('/admin/departments', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            await router.push('/admin/department');
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
        }
    }

    const updateDepartment = async (update) => {
        let data = update.department._value;
        errors.value = ''
        try {
            await axios.put('/admin/departments/' + update.department._value.id, data, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            await router.push('/admin/department');
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
        }
    }

    const deleteDepartment = async (id) => {
        errors.value = ''
        try {
            await axios.delete('/admin/departments/'+id, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } });
            getDepartments(1);
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
        }
    }

    return {
        departments,
        getDepartments,
        comboDepartment,
        getComboDepartment,
        getPublicComboDepartment,
        department,
        getAnDepartment,
        storeDepartment,
        updateDepartment,
        deleteDepartment,
    }
}
