<template>
  <div class="mb-4">
    <div class="mt-4">
      <div class="fs-5 text-dark">Willkommen bei</div>
      <div class="fs-1 text-yellow mt-1">Ruwisch & Zuck</div>
    </div>
  </div>
  <div>
    <div class="menu-container">
      <div
        class="navigation-option d-flex align-items-center bg-white px-4 py-4 rounded-3 mt-3"
        @click="router.push('/register')"
      >
        <div class="option-icon me-3">
          <i class="fas fa-arrow-right fs-6 me-1"></i
          ><i class="fas fa-door-open fs-5"></i>
        </div>
        <div class="option-label fs-5">Besuch anmelden</div>
      </div>
      <div
        class="navigation-option d-flex align-items-center bg-white px-4 py-4 rounded-3 mt-3"
        @click="router.push('/visitors')"
      >
        <div class="option-icon me-3">
          <i class="fas fa-door-open fs-5 me-1"></i
          ><i class="fas fa-arrow-right fs-6"></i>
        </div>
        <div class="option-label fs-5">Auschecken</div>
      </div>
    </div>
    <div class="mt-5 d-flex justify-content-end">
      <a href="#"></a>
    </div>
  </div>
  <img
    :src="`${backendURL}storage/images/background.png`"
    alt=""
    class="app-bg"
  />
</template>

<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();

    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      router,
    };
  },
};
</script>
