<template>
    <div class="w-75 p-5 bg-white">
        <div class="mb-4">
            <div>
                <div class="fs-5 fw-bold text-dark lh-1 title-line">Set Background</div>
            </div>
        </div>
        <div class="visitor-section">
            <div class="table-responsive">
                <form action="" @submit.prevent="updateData">
                    <div class="col-8 col-sm-10 mb-2">
                        <div class="form-floating">
                            <input type="file" accept=".jpg,.png" @change="onChange" style="display:block; margin-bottom : 50px;" class="btn btn-success"/>
                            <div id="preview">
                                <img v-if="item.imageUrl" :src="item.imageUrl" style="height:385px; width:580px;" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>    
    </div>
</template>

<script>

import settingServices from "../../services/SettingServices";

export default {
    name: 'imageUpload',
    data() {
        return {
            item:{
                image : null,
                imageUrl: `${process.env.VUE_APP_BACKEND_URL}storage/images/background.png`
            }
        }
    },
  methods: {
    
    onChange(e) {
        const { fileUpload } = settingServices();
        const file = e.target.files[0];
        const formdata = new FormData();
        formdata.append('background', file);

            fileUpload(formdata);

        this.image = file
        this.item.imageUrl = URL.createObjectURL(file)
    }
  }
} 
</script>