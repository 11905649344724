<template>
    <div class="vue-tempalte">
        <div class="modal-backdrop" id="myModal">
            <form @submit.prevent="authentication">
                <div class="modal-dialog ">
                    <div class="modal-content">

                        <!-- Modal Header -->
                        <div class="modal-header">
                            <h4 class="modal-title">Log In</h4>
                            <router-link to="/home" exact >
                                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                            </router-link>
                        </div>

                        <!-- Modal body -->
                        <div class="modal-body">
                            
                                <div class="form-floating mb-2">
                                    <input type="email" v-model="form.email" class="form-control form-control-lg" />
                                    <label>Email address</label>
                                </div>

                                <div class="form-floating">
                                    <input type="password" v-model="form.password" class="form-control form-control-lg" />
                                    <label>Password</label>
                                </div>
                        
                        </div>

                        <!-- Modal footer -->
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Log In</button>
                            <router-link to="/home" exact >
                                <button type="button" class="btn btn-danger mx-sm-1">Cancel</button>
                            </router-link>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import loginServices from "../../../services/LoginServices";
    import { reactive, onMounted } from "vue";

    export default {
       setup() {
            const form = reactive({
                email: "",
                password: "",
            });
            const { emailAuthentication, redirect } = loginServices();
            const authentication = async () => {
                await emailAuthentication({ ...form });
            };
    
            onMounted(redirect());

            return {
                form,
                authentication,
            };
        },
    }
</script>