<template>
    <ul class="pagination" :style="ulStyles" v-if="count > 1">
        <li class="page-item first" :style="liStyles">
            <a class="page-link" v-if="active != 1" @click="setPage(1)" :style="aStyles">First</a>
        </li>
        <li class="page-item previous" :style="liStyles" >
            <a class="page-link" v-if="active != 1" @click="setPage(active-1)" :style="aStyles">Previous</a>
        </li>
        <template v-if="count > 5">
            <template v-for="index in 5" :key="index">
                <li class="page-item page-number" :class="[active == start + index - 1? 'active': '']" :style="liStyles">
                    <a class="page-link" @click="setPage(start + index - 1)" :style="aStyles">{{start + index - 1}}</a>
                </li>
            </template>
        </template>
        <template v-if="count < 5">
            <template v-for="index in count" :key="index">
                <li class="page-item page-number" :class="[active == start + index - 1? 'active': '']" :style="liStyles">
                    <a class="page-link" @click="setPage(start + index - 1)" :style="aStyles">{{start + index - 1}}</a>
                </li>
            </template>
        </template>
        
        <li class="page-item next" :style="liStyles" >
            <a class="page-link" v-if="active != count" @click="setPage(active + 1)" :style="aStyles">Next</a>
        </li>
        <li class="page-item last" :style="liStyles">
            <a class="page-link" v-if="active != count" @click="setPage(count)" :style="aStyles">Last</a>
        </li>
    </ul>
</template>

<script>

export default { 
    props: ['count'],
    emits: ['setPage'],
    data: function() {
        return {
            active: 1,
            start: 1,
        };
    },
    methods: {
        setPage (page) {
            this.active = page;
            this.start = this.active - 2;
            if(this.start > this.count - 4){
                this.start = this.count - 4;
            }
            if(this.start <= 0){
                this.start = 1;
            }
            this.$emit('setPage', page);
        }
    },
}
</script>