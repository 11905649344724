import axios from '../util/api';

export default function settingServices() {
    const fileUpload = async (fromdata) => {
        await axios.post('/admin/settings/background-upload',fromdata, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } });
    }

    return {
        fileUpload,
    }
}