import { ref } from 'vue'
import axios from '../util/api';
import { useRouter } from 'vue-router';

export default function userServices() {
    const router = useRouter()
    const users = ref({});
    const comboUser = ref({});
    const user = ref('');
    const errors = ref('')

    const getUsers = async (page) => {
        const response = await axios.get('/admin/users/page/' + page, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        users.value = response.data;
    }

    const getComboUser = async () => {
        const response = await axios.get('/admin/users', { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        comboUser.value = response.data;
    }

    const getPublicComboUser = async () => {
        const response = await axios.get('/users')
        comboUser.value = response.data;
    }

    const getAnUser = async () => {
        const response = await axios.get('/admin/users/' + router.currentRoute._value.params.id, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } });
        user.value = response.data.data;
    }

    const storeUser = async (data) => {
        errors.value = ''
        try {
            await axios.post('/admin/users', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            await router.push('/admin/user');
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
        }
    }

    const updateUser = async (updata) => {
        console.log('test');
        console.log(updata);
        let data = updata.user._value;
        errors.value = ''
        try {
            await axios.put('/admin/users/' + updata.user._value.id, data, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            await router.push('/admin/user');
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
        }
    }

    const deleteUser = async (id) => {
        errors.value = ''
        try {
            await axios.delete('/admin/users/'+id, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } });
            getUsers(1);
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
        }
    }

    return {
        users,
        getUsers,
        comboUser,
        getComboUser,
        getPublicComboUser,
        user,
        getAnUser,
        storeUser,
        updateUser,
        deleteUser,
    }
}