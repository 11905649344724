<template>
  <div class="w-100 p-4 bg-white">
    <div class="mb-4">
      <div>
        <div class="fs-5 fw-bold text-dark lh-1 title-line">Visitor List</div>
      </div>
    </div>
    <div class="visitor-section">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Department</th>
              <th scope="col">Employee</th>
              <th scope="col">Visitor</th>
              <th scope="col">Company</th>
              <th scope="col">License Plate</th>
              <th scope="col">Check In</th>
              <th scope="col">Check Out</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item) in visitors" :key="item.id">
              <td v-if="item.department">{{ item.department.name }}</td>
              <td v-else></td>
              <td v-if="item.user">{{ item.user.name }}</td>
              <td v-else></td>
              <td>{{ item.fullName }}</td>
              <td>{{ item.company }}</td>
              <td>{{ item.license_plate }}</td>
              <td>{{ item.check_in_at }}</td>
              <td v-if="item.check_out_at">{{ item.check_out_at }}</td>
              <td v-else>
                <button class="btn_tp" style="height:30px !importent"  data-bs-toggle="tooltip" title="Log out" @click="deleteVisitor(item.id)">
                  <i class="fas fa-sign-out-alt fs-6 me-1 del_icon"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import adminServices from "../../services/AdminServices";

export default {
  setup() {
    const { visitors, getVisitors,destroyVisitor } = adminServices();

    const deleteVisitor = async (id) => {
			await destroyVisitor(id);
		};

    onMounted(getVisitors());
    return {
      visitors,
      deleteVisitor,
    };
  },
};
</script>