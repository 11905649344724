<template>
    <div class="d-flex align-items-center justify-content-center h-100">
		<div>
			<div class="d-flex align-items-center">
				<i class="fas fa-check-circle fs-1 me-3 text-success"></i>
				<h1 class="fs-1">Vielen Dank!</h1>
			</div>
			<div class="d-flex justify-content-center mt-5">
				<router-link :to="{path: '/home'}" class="btn btn-light fw-bold shadow text-dark-gray">Hauptmenü</router-link>
			</div>
		</div>
	</div>
</template>

<script>

export default {
    setup() {

        return {
        }
    }
}
</script>
