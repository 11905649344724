<template>
  <div class="sidebar-sticky" style="margin-top:30px;">
    <ul class="nav flex-column">
      <li class="nav-item">
        <router-link class="nav-link justify-content-start align-items-center" to="/admin/visitors" exact >
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home" >
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
              <polyline points="9 22 9 12 15 12 15 22"></polyline>
            </svg>
            Visitors
          </p>
        </router-link>
      </li>
      <li class="nav-item" v-if="authUserRole == 'admin'">
        <router-link class="nav-link justify-content-start align-items-center" to="/admin/department" exact >
          <p>Departments</p>
        </router-link>
      </li>
      <li class="nav-item" v-if="authUserRole == 'admin'">
        <router-link class="nav-link justify-content-start align-items-center" to="/admin/user" exact >
          <p>Employees</p>
        </router-link>
      </li>
      <li class="nav-item" v-if="authUserRole == 'admin'">
        <router-link class="nav-link justify-content-start align-items-center" to="/admin/setting" exact >
          <p>Settings</p>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  setup() {
    const authUserRole = localStorage.getItem('role');
    return {
        authUserRole
    };
  },
};
</script>

<style scoped>
.nav-item {
  padding: 0px !important;
}
</style>