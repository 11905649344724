<template>
  <div class="w-75 p-4 bg-white">
    <div class="mb-4">
      <div>
        <div class="fs-5 fw-bold text-dark lh-1 title-line">User Edit</div>
      </div>
    </div>
    <div class="visitor-section">
        <div class="table-responsive">
            <form action="" @submit.prevent="updateData" class="p-2">
              <input type="password" style="display: none;">
              <div class="col-8 col-sm-10 mb-2">
                <div class="form-floating">
                  <input type="text" class="form-control" v-model="user.name" />
                  <label>Name</label>
                </div>
              </div>
              <div class="col-8 col-sm-10 mb-2">
                <div class="form-floating">
                  <input type="email" class="form-control" v-model="user.email" />
                  <label>E-mail</label>
                </div>
              </div>
              <div class="col-8 col-sm-10 mb-2">
                <div class="form-floating">
                  <input type="password" class="form-control" v-model="user.password" />
                  <label>Password</label>
                </div>
              </div>
              <div class="col-8 col-sm-10 mb-2">
                <div class="form-floating">
                  <input type="password" class="form-control" v-model="user.passwordRepeat" />
                  <label>Repeat password</label>
                </div>
              </div>
              <div class="col-8 col-sm-10 mb-2">
                <div class="form-floating">
                  <select class="form-select" v-model="user.department">
                      <option selected></option>
                      <option
                        v-for="item in comboDepartment.data"
                        :key="item.id"
                        v-bind:value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  <label>Department Name</label>
                </div>
              </div>
              <div class="col-8 col-sm-10 mb-2">
                <router-link to="/admin/user" exact >
                  <button type="button" class="btn btn-danger mx-lg-3 float-end">Cancel</button>
                </router-link>
                <button type="submit" class="btn btn-primary mx-lg-3 float-end">Save</button>
              </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import departmentServices from "../../../services/DepartmentServices";
import userServices from "../../../services/UserServices";

export default {
  setup() {
    const { comboDepartment, getComboDepartment } = departmentServices();
    const { user, getAnUser, updateUser } = userServices();
    const getData = async (id) => {
      await getAnUser(id);
      await getComboDepartment();
    };
    let flag = true;
    const updateData = async () => {
      if(flag){
        flag = false;
        await updateUser({user});
      }
    };
    onMounted(getData());
    return {
      user,
      updateData,
      comboDepartment,
    };
  },
};
</script>
