import { createRouter, createWebHistory } from "vue-router";
// import { useRouter } from 'vue-router';

import Home from '../pages/user/Home.vue';
import Register from '../pages/user/Register.vue';
import Visitors from '../pages/user/Visitors.vue';
import Thanks from '../pages/user/Thanks.vue';
import AdminVisitors from '../pages/admin/Visitors.vue';
import AdminDepartment from '../pages/admin/department/index.vue';
import AdminDepartmentCreate from '../pages/admin/department/DepartmentCreate.vue';
import AdminDepartmentEdit from '../pages/admin/department/DepartmentEdit.vue';
import AdminUser from '../pages/admin/user/index.vue';
import AdminUserCreate from '../pages/admin/user/UserCreate.vue';
import AdminUserEdit from '../pages/admin/user/UserEdit.vue';
import AdminSetting from '../pages/admin/Setting.vue';
import AdminLogin from '../pages/admin/login/Login.vue';
import UserLayout from '../layout/UserLayout.vue';
import AdminLayout from '../layout/AdminLayout.vue';
import VisitorsSignout from '../pages/user/VisitorsSignout.vue';

// const router = useRouter();
const routes = [
    {
        path: '/',
        redirect: 'home',
        component: UserLayout,
        children: [
            {
                path: '/home',
                name: 'home',
                component: Home
            },
            {
                path: '/register',
                name: 'register',
                component: Register
            },
            {
                path: '/visitors/checkout/:signoutKey',
                name: 'visitorsSignout',
                component: VisitorsSignout
            },
            {
                path: '/visitors',
                name: 'visitors',
                component: Visitors
            },
            {
                path: '/thanks',
                name: 'thanks',
                component: Thanks
            },
        ]
    },
    {
        path: '/admin',
        component: AdminLayout,
        redirect: '/admin/visitors',
        children: [
            {
                path: '/admin/visitors',
                component: AdminVisitors
            },
            {
                path: '/admin/department',
                component: AdminDepartment
            },
            {
                path: '/admin/department/create',
                component: AdminDepartmentCreate,
            },
            {
                path: '/admin/department/edit/:id',
                component: AdminDepartmentEdit,
            },
            {
                path: '/admin/user',
                component: AdminUser
            },
            {
                path: '/admin/user/create',
                component: AdminUserCreate,
            },
            {
                path: '/admin/user/edit/:id',
                component: AdminUserEdit,
            },
            {
                path: '/admin/setting',
                component: AdminSetting,
            },
        ]
    },
    {
        path: '/admin/login',
        component: AdminLogin,
    },
]



const router = createRouter(
    {
        history: createWebHistory(),
        routes
    }
)

export default router;

router.beforeEach((to, from, next) => {
    const publicPages = [ '/home','/register','/visitors','/thanks','/admin/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token');

    if (authRequired && !loggedIn) {
      next('/admin/login');
    } else {
      next();
    }
  });